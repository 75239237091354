import SearchArea from './components/SearchArea/SearchArea';
import ResultArea from './components/ResultArea/ResultArea';
import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer";
import React, {useEffect} from 'react';
import {redirect, useSearchParams} from 'react-router-dom';

const API_DOMAIN = process.env.REACT_APP_HOLO_DBD_API_DOMAIN;

function App() {
    const [members, setMembers] = React.useState(['']);
    const [searchCondition, setSearchCondition] = React.useState([]);
    const [fetchedVideos, setFetchedVideos] = React.useState([]);
    const [videos, setVideos] = React.useState([]);
    const [selectTab, setSelectTab] = React.useState(0);
    const [hideZeroCount, setHideZeroCount] = React.useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const url = `${API_DOMAIN}/api/channels`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const formatted = data.map((channel) => {
                    return {"name": channel.Name, "channelId": channel.ID, "userName": channel.UserName};
                });
                setMembers(formatted);

                const channelIdQuery = searchParams.get('channelId')?.split(',') ?? [];
                const userNameQuery = searchParams.get('userName')?.split(',') ?? [];
                const condition = [
                    ...formatted.filter((member) => {
                        return channelIdQuery.includes(member.channelId)
                    }),
                    ...formatted.filter((member) => {
                        return userNameQuery.includes(member.userName)
                            && !channelIdQuery.includes(member.channelId)
                    })]
                if (condition.length !== 0) {
                    setSearchCondition(condition);
                    setSearchParams()
                    redirect('/')
                }
            })
            .catch((error) => {
                // TODO エラー処理
                console.error('Error:', error);
            });
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (searchCondition.length === 0) {
            setFetchedVideos([]);
            return;
        }

        const channelIds = searchCondition.map((member) => encodeURIComponent(member.channelId)).join(",");
        const url = `${API_DOMAIN}/api/videos?channelId=${channelIds}`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setFetchedVideos(data);
            })
            .catch((error) => {
                // TODO エラー処理
                console.error('Error:', error);
            });
    }, [searchCondition]);

    useEffect(() => {
        if (fetchedVideos.length === 0) {
            setVideos([]);
            setSelectTab(0);
            return;
        }
        const viewedVideos = fetchedVideos.map((video) => {
            const member = members.find((member) => member.channelId === video.owner);
            const isHidden = hideZeroCount && video.videos.length === 0;
            return {...video, owner: member.name, disabled: video.videos.length === 0, isHidden: isHidden}
        });
        setVideos(viewedVideos);
        if (selectTab > fetchedVideos.length - 1) {
            setSelectTab(fetchedVideos.length - 1);
        }
    }, [fetchedVideos, selectTab, members, hideZeroCount]);

    return (
        <>
            <Header/>
            <SearchArea members={members} condition={searchCondition} onConditionChange={setSearchCondition}
                        hideZeroCount={hideZeroCount} onHideZeroCountChange={setHideZeroCount}
                        sx={{flex: "1 0 auto", paddingBottom: '100px'}}/>
            {videos.length > 0 && <ResultArea videoList={videos} tab={selectTab} onTabChange={setSelectTab}
                                              sx={{flex: "1 0 auto", paddingBottom: '100px'}}/>}
            <Footer sx={{flexShrink: 0}}/>
        </>
    );
}

export default App;
