import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const SearchForm = function SearchForm({members, condition, onConditionChange, hideZeroCount, onHideZeroCountChange}) {
    const changeFunc = (event, values) => {
        onConditionChange(values);
    };

    const handleCheckboxChange = (event) => {
        onHideZeroCountChange(event.target.checked);
    };

    return (
        <Stack spacing={3} sx={{width: 500}}>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={members}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={changeFunc}
                value={condition != null ? condition : []}
                isOptionEqualToValue={(option, value) => option.channelId === value.channelId}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="検索条件"
                        placeholder="メンバーの名前"
                    />
                )}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hideZeroCount}
                        onChange={handleCheckboxChange}
                        name="hideZeroCount"
                    />
                }
                label="0件は表示しない"
            />
        </Stack>
    );
}

export default SearchForm;