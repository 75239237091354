import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

const Header = function Header() {
    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2%'}}>
                <Typography variant='h4'>
                    Holo DBD Playlist
                </Typography>
            </Box>
        </>
    );
};

export default Header;