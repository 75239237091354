import React from 'react';
import SearchForm from '../SearchForm/SearchForm';
import {Box} from '@mui/material';

const SearchArea = function SearchArea({members, condition, onConditionChange, hideZeroCount, onHideZeroCountChange}) {
    return (
        <Box
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%', marginBottom: '2%'}}>
            <SearchForm
                members={members}
                condition={condition}
                onConditionChange={onConditionChange}
                hideZeroCount={hideZeroCount}
                onHideZeroCountChange={onHideZeroCountChange}
            />
        </Box>
    );
}

export default SearchArea;