import React from "react";
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import VideoCard from "../VideoCard/VideoCard";
import Grid from "@mui/material/Unstable_Grid2";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    // https://github.com/mui/material-ui/issues/21015
    // change Typography to Paper
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Paper>{children}</Paper>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ResultArea = function ResultArea({videoList, tab, onTabChange}) {

    const handleChange = (event, newValue) => {
        onTabChange(newValue);
    };

    const tabs = videoList.map((item, index) => {
        const label = item.owner + '(' + item.videos.length + '件)'
        return <Tab label={label} {...a11yProps(index)} key={label} disabled={item.disabled}
                    style={item.isHidden ? {display: 'none'} : {}}/>
    });

    const tabPanels = videoList.map((elem, index) => {
        const videoCards = elem.videos.map((video) => {
            return (
                <Grid xs={6} sm={4} md={3} key={`grid-${video.VideoId}-${index}`} sx={{
                    transition: '0.3s',
                    '&:hover': {backgroundColor: '#e0e0e0'},
                }}>
                    <VideoCard video={video} key={`videocard-${video.VideoId}`}/>
                </Grid>
            )
        });
        return (
            <TabPanel value={tab} index={index} key={`tabpanel-${index}`}>
                <Box sx={{flexGrow: 1, marginLeft: '5%', marginRight: '5%'}} key={`box-${index}`}>
                    <Grid container spacing={2} key={`grid-${index}`}>
                        {videoCards}
                    </Grid>
                </Box>
            </TabPanel>
        )
    });

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginLeft: '5%',
                marginRight: '5%',
                alignItems: 'center'
            }}>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="result area tabs"
                >
                    {tabs}
                </Tabs>
            </Box>
            {tabPanels}
        </Box>
    );
}

export default ResultArea;