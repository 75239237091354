import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

const VideoCard = function VideoCard({video}) {
    const thumbnail = video.Thumbnails.high
    const videoUrl = `https://www.youtube.com/watch?v=${video.VideoId}`
    const MAX_LENGTH = 100
    const title = video.Title
    const displayTitle = title.length > MAX_LENGTH ? title.slice(0, MAX_LENGTH) + '...' : title;

    return (
        <Link
            href={videoUrl}
            target="_blank"
            rel="noopener"
            underline="none"
        >
            <Card>
                <CardMedia
                    component="img"
                    alt={video.Title}
                    maxwidth={thumbnail.width}
                    image={thumbnail.url}
                />
                <CardContent>
                    <Typography gutterBottom variant="caption">
                        {video.PublishedAt}
                    </Typography>
                    <Tooltip title={title}>
                        <Typography gutterBottom variant="subtitle1"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        overflow: 'hidden'
                                    }}>
                            {displayTitle}
                        </Typography>
                    </Tooltip>
                </CardContent>
            </Card>
        </Link>
    );
}

export default VideoCard;