import React from 'react';
import Box from '@mui/material/Box';
import {Link, Typography} from '@mui/material';

const Footer = function Footer() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90%',
                backgroundColor: 'white',
                borderTop: 1,
                borderColor: 'divider',
                paddingY: '1%',
                marginX: '5%'
            }}
        >
            <Typography variant='body1' color="black">
                Contact:
                <Link
                    href="https://twitter.com/holo_dbd"
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                    sx={{
                        color: 'blue',
                        '&:hover': {
                            color: 'darkblue',
                        },
                    }}
                >
                    @holo_dbd
                </Link>
                (Twitter)
            </Typography>
        </Box>
    );
};

export default Footer;
